import Title from "../components/Title";
import Reminder from "../components/Reminder";
import Feedback from "../components/Feedback";

const people = [
  {
    name: 'Николай Волков',
    role: 'Руководитель проекта',
    imageUrl: `${process.env.PUBLIC_URL}/team/nikolay.png`,
    bio: 'Проект, который ощущается по-другому',
  },
  {
    name: 'Владислав Крамаренко',
    role: 'Туристический агент',
    imageUrl: `${process.env.PUBLIC_URL}/team/vladislav.jpg`,
    bio: 'Я знаю, что ничего не знаю, но другие не знают и этого!',
  },
  {
    name: 'Елена Жванская',
    role: 'Ведущий методист',
    imageUrl: `${process.env.PUBLIC_URL}/team/elena.jpg`,
    bio: 'Жизнь – как езда на велосипеде. Чтобы сохранять равновесие, нужно двигаться',
  },
  {
    name: 'Ксения Караваева',
    role: 'Дизайнер',
    imageUrl: `${process.env.PUBLIC_URL}/team/ksenia.jpg`,
    bio: 'Вы не понимаете, это другое',
  },
  {
    name: 'Ярослав Кардеев',
    role: 'Back-end разработчик',
    imageUrl: `${process.env.PUBLIC_URL}/team/yaroslav.jpg`,
    bio: '{\n' +
      '"Response": "Ответ!"\n' +
      '}',
  },
  {
    name: 'Василий Есипенко',
    role: 'Front-end разработчик',
    imageUrl: `${process.env.PUBLIC_URL}/team/vasily.jpg`,
    bio: '{\n' +
      '"Request": "Вопрос?"\n' +
      '}\n',
  },
  {
    name: 'Инна Алексеева',
    role: 'Маркетолог',
    imageUrl: `${process.env.PUBLIC_URL}/team/inna.jpg`,
    bio: 'Если вы нашли нас благодаря рекламе, значит я хорошо поработала!',
  },
  {
    name: 'Софья Сутягина',
    role: 'Видеограф',
    imageUrl: `${process.env.PUBLIC_URL}/team/sofia.jpeg`,
    bio: 'Жить, любить, мечтать, творить и быть собой!',
  },
];

export default function Team() {
  return (
    <div>
      <div className="bg-bgLight min-h-screen">
        <div className="relative isolate overflow-hidden pt-80 min-h-screen">
          <div className="mx-auto max-w-full mt-[-12rem]">
            <div className="text-center">
              <Title title="Команда проекта" />
              <p className="mx-6 sm:mx-auto max-w-3xl mt-6 text-16 leading-8 text-main">
                Мы — молодые ученые, активисты студенческих научных обществ и неравнодушные студенты. Популяризируем отечественную науку, открывая нашу необъятную страну!
              </p>
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-12">
              <ul
                className="mx-auto mt-12 sm:mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-4"
              >
                {people.map((person) => (
                  <li key={person.name}>
                    <img className="aspect-[3/4] object-cover" src={person.imageUrl} alt="" />
                    <h3 className="mt-6 text-24 font-rubikSemi leading-8 tracking-tight text-slight">{person.name.split(' ')[0]}<br/>{person.name.split(' ')[1]}</h3>
                    <p className="my-2 text-16 leading-7 text-gray-700 text-slight">{person.role}</p>
                    <p className="text-16 italic text-pretty leading-7 text-gray-700">«{person.bio}»</p>
                  </li>
                ))}
              </ul>
            </div>
            <Reminder />
            <Feedback theme='light' hideTeam={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
